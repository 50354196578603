<template>
  <div v-if="hasError" class="text-negative text-center text-subtitle1">
    {{ $t('Format of received task is wrong. Please connect with support!') }}
  </div>

  <div v-else class="border rounded">
    <div
        v-for="(item, i) in data"
        :key="item.id"
        class="clickable justify-center items-stretch"
        :class="`${getClassName(item)} ${i < data.length - 1 && data.length > 1 ? 'border-bottom' : ''}`"
        @dblclick="handleClick(item)"
    >

      <div class="row justify-center items-center q-mb-sm" :class="{ 'bg-grey': isRejected(item) }">

        <div class="col text-center" style="display: flex;flex-wrap: nowrap;justify-content: center;">
          <q-btn
              icon="build_circle_outline"
              color="orange"
              fab
              class="q-my-sm q-mx-md block fab"
              size="sm"
              dark
              @click="handleRejectOrderProductTask(item)"
          >
            <q-tooltip self="center middle">
              Reject order product
            </q-tooltip>
          </q-btn>

          <q-btn
              v-if="item?.instructions"
              icon="play_circle_outline"
              color="primary"
              fab
              class="q-my-sm q-mr-sm block fab"
              size="sm"
              dark
              @click="handleInstructionClick(item)"
          >
            <q-tooltip self="center middle">
                {{ $t('Print labels') }}
            </q-tooltip>
          </q-btn>

          <q-btn
              icon="inventory"
              color="primary"
              fab
              class="q-my-sm q-mr-sm block fab"
              size="sm"
              dark
              @click="handleBoxesClick(item)"
          >
            <q-tooltip self="center middle">
              Boxes
            </q-tooltip>
          </q-btn>
        </div>

        <div
            class="col q-py-xs row justify-center items-center text-center"
            @click="handleImageClick(item._embedded.orderProduct.productOfferRaw)"
        >
          <img
              style="height: 76px; width: 100%; object-fit: contain;"
              :src="item._embedded.orderProduct.productOfferRaw?.image || fallbackImage"
              :alt="item._embedded.orderProduct.productOfferRaw?.name"
              @error="onImageLoadFailure"
          >
        </div>

        <div class="col-3 q-pa-xs text-center">

          <label v-if="item._embedded.orderProduct.productOfferRaw?.type === 'bundle'"
                 :class="`q-py-xs q-px-sm q-mr-sm rounded bg-accent text-caption text-h6 text-bold text-white`">
            Bundle
          </label>

          <div>
            <new-picking-place
                v-if="item._embedded && item._embedded.place"
                tooltip="Location"
                :data="item._embedded.place"
            />
          </div>
          <q-tooltip self="center middle">
            Location
          </q-tooltip>

        </div>

        <div
            class="col-md-2 col-lg-2 q-py-xs row justify-center items-center text-center text-h6 text-bold"
            style="min-height: 100%;"
        >
          <div>
            {{ item.scannedCount + '/' + item.count }}
          </div>

          <q-tooltip self="center middle">
            Quantity
          </q-tooltip>
        </div>

        <div class="col text-center">
          <strong tooltip="Name">
            {{ item._embedded.orderProduct.productOfferRaw?.name }}
            <span>({{ $t('ID') + ': ' + item._embedded.orderProduct.productOfferRaw?.id }})</span>
          </strong>

          <q-tooltip self="center middle">
            Name
          </q-tooltip>
        </div>

        <div class="col-md-2 col-lg-2 text-center">
          <strong tooltip="Barcode">{{ getBarcode(item).join(', ') }}</strong>
          <q-tooltip self="center middle">
            Barcode
          </q-tooltip>
        </div>

      </div>
    </div>

    <portal to="assembling-items">
      <div class="text-h6 text-bold">
        {{
          `${details.items} ${$t(`item${details.items > 1 ? 's' : ''}`)} (${details.products} ${$t(`SKU${details.products > 1 ? 's' : ''}`)})`
        }}
      </div>
    </portal>

    <q-dialog
        v-model="isOpenImage"
        persistent
        :maximized="true"
        transition-show="slide-up"
        transition-hide="slide-down"
    >
      <q-card
          class="row items-center justify-center"
          style="background: rgba(0, 0, 0, .7);"
          @click="handleImage"
      >
        <img
            style="height: 90vh; width: 90vw; object-fit: contain;"
            :src="image || fallbackImage"
            @error="onImageLoadFailure"
        >
      </q-card>
    </q-dialog>
  </div>

  <quantity-modal ref="quantity" @submit="rejectOrderProductTask"></quantity-modal>
</template>

<script>
// Components
import newPickingPlace from '../picking/new-picking-place.vue'
import { InstructionsService } from '@/apps/app/services/instructions.service'
import QuantityModal from '@/apps/app/components/modals/QuantityModal.vue'

export default {
  name: 'DynamicTask',
  emits: ['click'],
  components: {
    QuantityModal,
    newPickingPlace
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpenImage: false,
      image: null,
      fallbackImage: 'assets/img/fallback-image/package.png'
    }
  },
  mounted () {
  },
  computed: {
    hasError () {
      return this.data.find(x => !x._embedded || !x._embedded.orderProduct)
    },
    details () {
      return {
        items: this.data.reduce((acc, item) => {
          if (!item._embedded || !item._embedded.orderProduct) {
            return acc
          }

          return acc + Number(item._embedded.orderProduct.count)
        }, 0),
        products: this.data.length
      }
    }
  },
  methods: {
    getClassName (offer) {
      if (offer.scannedCount > 0 && offer.scannedCount < offer.count) {
        return 'bg-warning'
      }

      if (offer.scannedCount >= offer.count) {
        return 'bg-positive'
      }

      return ''
    },
    handleImageClick (offer) {
      this.image = offer.image || this.fallbackImage
      this.isOpenImage = true
    },
    handleImage () {
      this.isOpenImage = false
      this.image = null
    },
    getBarcode (product) {
      if (product.instructions) {
        let barcodes = (product.instructions.find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode

        return Object.values(barcodes)
      }

      if (product._embedded.orderProduct.productOfferRaw && Array.isArray(product._embedded.orderProduct.productOfferRaw?.barcodes)) {
        return product._embedded.orderProduct.productOfferRaw?.barcodes
      }

      return []
    },
    handleClick (data) {
      const item = {
        type: this.rawData.type,
        event: this.rawData.event,
        data
      }

      this.$emit('click', item)
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    getColor (item) {
      console.log(this.data)
      if (item.scannedCount === 0) {
        return 'danger'
      }

      if (item.scannedCount === item.count) {
        return 'green'
      }

      return 'yellow'
    },
    handleRejectOrderProductTask (item) {
      this.$refs.quantity.open(item)
    },
    rejectOrderProductTask (item) {
      const eventItem = {
        type: 'storage.queue.task.order.product.reject',
        data: {
          id: item.id,
          quantity: item.quantity
        },
      }

      this.$emit('click', eventItem)
    },
    handleInstructionClick (item) {
      const test = item.instructions.filter(e => e.type === 'label-print')

      if (test) {
        return this.executeInstructions(test)
      }
    },
    executeInstructions (instructions) {
      const instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))
      const reducedInstructions = instructions.reduce((acc, instruction) => {
        if (Array.isArray(instruction)) {
          acc = [...acc, ...instruction]
        } else {
          acc.push(instruction)
        }

        return acc
      }, [])

      return instructionsService.execute(reducedInstructions)
          .then(result => {
            return result
          })
    },
    isRejected (item) {
      return item.state === 'rejected'
    },
    handleBoxesClick (item) {
      const eventItem = {
        type: 'storage.queue.task.list.boxes',
        data: item,
      }

      this.$emit('click', eventItem)
    },
  }
}
</script>
