<template>
  <q-dialog
    v-model="isOpen"
    persistent
  >
    <q-card style="min-width: 50vw;">
      <q-card-section>
        <div class="text-h6 text-center">
          {{ $t('Scan barcode') + ' - ' + validateValue }}
        </div>
        <div v-if="options.options && options.options.label" class="text-h6 text-center">
            {{ $t(options.options.label)}}
        </div>
      </q-card-section>

      <q-card-section v-if="isOpen && !options.okOnly">
        <barcode-input
          :settings="{catchAll: false}"
          :has-max-width="false"
          @barcode="handleBarcode"
        />
      </q-card-section>

      <q-card-section
        v-if="options.noSkip"
        class="text-center"
      >
        <q-btn
          v-if="options.print"
          color="dark"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Print')"
          @click="options.print"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Discard')"
          @click="handleCancel"
        />
      </q-card-section>

      <q-card-section
        v-else-if="options.okOnly"
        class="text-center"
      >
        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Ok')"
          @click="handleOk"
        />
      </q-card-section>

      <q-card-section
        v-else
        class="text-center"
      >
        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Skip')"
          @click="handleSkip"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import BarcodeInput from '../barcode-input/BarcodeInput'

export default {
  name: 'BarcodeInputModal',
  components: {
    BarcodeInput
  },
  data () {
    return {
      isOpen: false,
      validateValue: null,
      options: {}
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'addNotification'
    ]),
    resolve () {},
    reject () {},
    show (validateValue, options = {}) {
      this.options = options
      this.validateValue = validateValue
      this.isOpen = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    hide () {
      this.isOpen = false
    },
    handleOk () {
      this.resolve(true)
    },
    handleCancel () {
      this.resolve(false)
    },
    handleSkip () {
      this.resolve(true)
    },
    handleBarcode (barcode) {
      if (Array.isArray(this.validateValue)) {
        const value = this.validateValue.find(val => val === barcode.raw)

        if (!value) {
          this.addErrorNotification('Barcode is not found')
          return
        }

        this.addNotification('Successful scanned!')
        this.resolve(barcode.raw)
        return
      }

      if (this.validateValue && barcode.raw !== this.validateValue) {
        this.addErrorNotification('Barcode is not the some')
        return
      }

      this.addNotification('Successful scanned!')
      this.resolve(barcode.raw)
    }
  }
}
</script>
