<template>
    <div class="row" style="align-items: center">
        <q-list
                class="col rounded"
        >
            <q-item
                    v-if="places && places.length > 1"
                    class="block text-right q-item-no-border full-width"
            >
                <q-card class="col no-border" flat borderless>
                    <q-card-section class="full-width row text-right no-wrap">
                        <div class="col align-items-center text-left">
                            <q-chip class="q-my-none text-center q-px-lg text-white" size="lg" square color="green-6">
                                {{ $t('P') }}
                            </q-chip>
                        </div>
                        <div class="col">
                            <q-btn-group class="q-px-xs">
                                <q-btn-dropdown
                                        size="md"
                                        flat
                                        round
                                        dense
                                        no-icon
                                        class="row justify-end"
                                        dropdown-icon="more_horiz"
                                >
<!--                                    <q-list>-->
<!--                                        <q-item clickable v-close-popup :disable="selectedItems.length === 0">-->
<!--                                            <q-item-section @click="handleItemsMove(selectedItems)">-->
<!--                                                <q-item-label>-->
<!--                                                    {{ 'Move item' }}-->
<!--                                                </q-item-label>-->
<!--                                            </q-item-section>-->
<!--                                        </q-item>-->

<!--                                        <q-item clickable v-close-popup :disable="selectedItems.length === 0">-->
<!--                                            <q-item-section @click="openConfirmation(selectedItems[0])">-->
<!--                                                <q-item-label>-->
<!--                                                    {{ 'Delete item' }}-->
<!--                                                </q-item-label>-->
<!--                                            </q-item-section>-->
<!--                                        </q-item>-->
<!--                                    </q-list>-->
                                </q-btn-dropdown>
                            </q-btn-group>
                        </div>
                    </q-card-section>

                    <q-card-section>

                        <pallet-pool-row :deliveryRequest="deliveryRequest.id" />
                    </q-card-section>
                </q-card>

            </q-item>
        </q-list>
    </div>

    <q-dialog v-model="areYouSure">
        <q-card style="max-width: 30vw">
            <q-card-section class="row justify-center items-center full-height text-center text-h6">
                <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
                    {{ $t('Are You Sure?') }}
                </div>

                <q-space/>

                <q-btn v-close-popup icon="close" flat round dense/>
            </q-card-section>
            <q-card-section class="row justify-center items-center full-height text-center text-h6">
                <q-card-section class="text-center">

                    <q-btn
                            textarea="center"
                            color="negative"
                            class="q-mr-sm"
                            text-color="white"
                            :label="$t('Delete product')"
                            @click="handleRemove()"
                    />

                    <q-btn
                            textarea="center"
                            :label="$t('Discard')"
                            @click="closeConfirmation"
                    />
                </q-card-section>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import PalletPoolRow from "@/apps/app/components/delivery-requests/PalletPoolRow.vue";

export default {
  name: 'ProductPool',
  emits: ['delete', 'dblclick', 'move-items', 'request-move-items', 'mark-shipped'],
  components: {PalletPoolRow },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    isBlocked: {
      type: Boolean,
      default () {
        return false
      }
    },
    deliveryRequest: {
      type: [String, Number],
      default () {
        return null
      }
    },
    places: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    place: {
      type: Object,
      default () {
        return {}
      }
    },
    itemToMove: {
      type: Object,
      default() {
        return null
      }
    },
    scanInput: {
      type: Object,
      default () {
        return null
      }
    },
  },
  mounted () {
    this.currentPlaces = this.places
    this.currentPlaces.sort((a, b) => b.priority - a.priority)

    if (this.place) {
      if (this.place.items) {
        this.items = this.place.items.map(e => {
          return { ...e, quantityToMove: 1 }
        })
      } else {
        this.items = this.place.items
      }
      if (this.place.dimensions) {
        this.width = this.place.dimensions.x
        this.length = this.place.dimensions.z
        this.height = this.place.dimensions.y
      }
      this.weight = this.place.weight
    }
    this.currentPlaces = this.places
  },
  data () {
    return {
      currentPlaces: null,
      itemsPool: null,
      items: [],
      selectedItems: [],
      itemToDelete: {},
      weight: null,
      height: null,
      width: null,
      length: null,
      areYouSure: false,
      fallbackImage: './assets/img/fallback-image/package.png',
      pagination: {
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
      isLoading: false,
      imageZoomDialog: false,
      zoomedSrc: '',
    }
  },
  watch: {
    places: {
      handler (value) {
        this.currentPlaces = value

        let selectedPlaceNew = this.currentPlaces.filter(e => {
          if (e.eav && e.eav['delivery-services-request-place-is-pool']) {
            if (e.eav['delivery-services-request-place-is-pool'] === true) {
              return e
            }
          }
        })

        this.itemsPool = selectedPlaceNew[0]
        if (this.itemsPool && this.itemsPool.items) {
          this.items = this.itemsPool.items
          this.items = this.items.map(e => {
            return { ...e, quantityToMove: 1 }
          })
        }
      },
      deep: true
    },
    isBlocked: {
      handler (value) {
        this.isLoading = value
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    highlightSubstring() {
      return (text, item) => {
        if(!this.scanInput) {
          return text
        }

        if (this.scanInput.type === '') {
          const value = `(${this.scanInput.value})`
          const regex = new RegExp(value, 'gi');

          if(this.itemToMove && this.itemToMove.id === item.id) {
            return text.replace(regex, (match) => {
              return `<span class="bg-positive">${match}</span>`;

            });
          } else if(this.itemToMove) {
            return text.replace(regex, (match) => {
              return `<span class="bg-yellow">${match}</span>`;
            });
          } else {
            return text
          }
        } else {
          return text
        }
      };
    },
    columns () {
      return [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('QTY'),
          name: 'quantity',
          align: 'center'
        },
        this.places.length > 1 ?
          {
            label: this.$t('QTY to move to last box'),
            name: 'quantityToMove',
            align: 'left'
          } : {},
      ]
    },
  },
  methods: {
    ...mapMutations([
      'addWarningNotification'
    ]),
    showImageZoomDialog(src) {
      this.zoomedSrc = src;
      this.imageZoomDialog = true;
    },
    closeImageZoomDialog() {
      this.zoomedSrc = '';
      this.imageZoomDialog = false;
    },
    handleDuplicate (item) {
      let itemChanges = {}
      Object.entries(item).forEach(([key, value]) => {
        if (value instanceof Object && value.id) {
          itemChanges[key] = value.id
        } else {
          itemChanges[key] = value
        }
      })

      itemChanges['place'] = this.place.id
      delete itemChanges.id
      delete itemChanges.created
      delete itemChanges.updated
      delete itemChanges.quantity

      itemChanges['deliveryRequest'] = this.place._embedded?.deliveryRequest?.id

      this.isLoading = true
      this.$service.deliveryServicePlaceItems.save(itemChanges).then(item => {
        itemChanges = {}
        this.$emit('moved')
        this.isLoading = false
      })
      return item

    },
    handleSelectItems (item) {
      let hasItem = false

      this.selectedItems = this.selectedItems.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (!hasItem) {
        this.selectedItems.push(item)
      }
    },
    handleQuantityUpdate (count, item) {
      item.count = parseInt(count)

      if (count >= 0) {
        return this.$service.deliveryServicePlaceItems.save({ 'count': count }, item.id)
      } else {
        return Promise.reject(new Error('Quantity to move should be positive value'))
      }
    },
    closeConfirmation () {
      this.areYouSure = false
    },
    handleRemove () {
      return this.$service.deliveryServicePlaceItems.save({ state: 'deleted' }, this.itemToDelete.id)
        .then(() => {
          this.$emit('moved')
        })
      this.areYouSure = false
    },
    openConfirmation (item) {
      this.itemToDelete = item
      this.areYouSure = true
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    handleTab (tab) {
      if (tab === 'box') {
        const data = {
          deliveryRequest: this.deliveryRequest,
          dimensions: null,
          volume: null,
          weight: null
        }

        if (this.currentPlaces && this.currentPlaces.length <= 1) {
          data.priority = 1
        }

        this.isLoading = true
        return this.$service.deliveryServicePlace.save(data)
          .then(place => {
            this.currentPlaces = [place, ...this.places]
            this.$emit('change', this.currentPlaces)
            this.isLoading = false
          })
          .then(() => {
            if (this.currentPlaces.length <= 2) {
              let currentPlace = _.cloneDeep(this.place)
              currentPlace.eav = { ...currentPlace.eav, 'delivery-services-request-place-is-pool': true }

              //     {
              //     ...this.place,
              //     eav: {'delivery-services-request-place-is-pool' : true}
              // }
              this.isLoading = true
              this.$service.deliveryServicePlace.save({
                priority: 0,
                eav: { ['delivery-services-request-place-is-pool']: true }
              }, currentPlace.id).finally(() => {
                this.isLoading = false
              })
            }
          })
      } else if (tab === 'product') {
        this.product = null
        this.$refs.drProduct.open()
      }
    },
    handleItemsMove (items = []) {
      this.$emit('request-move-items', {
        sourcePlace: this.itemsPool,
        items: items,
      })
    },
    handleItemSubmit (item, place, oldPlace) {
      this.$emit('move-items', {
        sourcePlace: oldPlace,
        targetPlace: place,
        items: [
          item,
        ],
      })
      this.$refs.drProduct.close()
      this.handleCloseItemModal()
    },
    handleCloseEditItem (value) {
      this.$emit('change-catch-all', value)
    },
    handleItemsMoveQuantity (quantity ,item ) {
      if (quantity >= 0) {
        this.quantityToMove = quantity
        item.quantityToMove = quantity
      }
    },
    updatePlaceDimensions (state, value, place) {
      if (Number(value) <= 0) {
        return
      }

      if (state === 'weight') {
        this.$service.deliveryServicePlace.save({ weight: value }, place.id)
      } else {
        if (!place.dimensions) {
          if (state === 'x') {
            this.width = value
          }
          if (state === 'y') {
            this.height = value
          }
          if (state === 'z') {
            this.length = value
          }
          if (this.width && this.length && this.height) {
            this.$service.deliveryServicePlace.save({
              dimensions: {
                x: this.width,
                y: this.height,
                z: this.length
              }
            }, place.id)
          }
        } else {
          let data = {
            ...place.dimensions,
          }
          data[state] = value
          this.$service.deliveryServicePlace.save({ dimensions: data }, place.id)
        }
      }
    },
    async handleMarkAsNotShipped(items = []) {
      this.$emit('mark-shipped' , {
        items: items,
      })
    },
    moveToLast (item) {
      this.selectedItems = []

      this.$emit('move-items', {
        sourcePlace: this.place,
        targetPlace: this.places[0],
        items: [
          item,
        ],
      })
    },
    disableCatchAll () {
      setTimeout(() => {
        this.$eventBus.update('catchAll', {
          catchAll: false
        })
      }, 10)
    },
    enableCatchAll () {
      this.$eventBus.update('catchAll', {
        catchAll: true
      })
    }
  }
}
</script>

<style scoped>
.highlighted {
    background-color: yellow; /* Customize to your desired highlight style */
    font-weight: bold; /* Customize to your desired highlight style */
    /* Add more styles as needed */
}
</style>